import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 10px 28px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 7%);
  }
`

export const Header = styled.div`
  width: 100%;
  text-align: center;
  background: var(--subtitle-color);
  padding: 30px 20px 24px;
`

export const Title = styled.div`
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
`

export const Subtitle = styled.div`
  font-size: 32px;
  font-weight: 300;
  color: #ffffff;
  text-transform: uppercase;
`

export const Content = styled.div`
  padding: 40px 30px;
  font-size: 13px;
  font-weight: 200;
  color: var(--text-color);
  border: 1px solid #e6e6e6;
`



