import React from "react";

import {Container, Header, Title, Subtitle, Content} from './Card.styles';

const Card = ({title, subtitle, content}) => {
    return (
        <Container>
            <Header>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Header>
            <Content>
                {content}
            </Content>
        </Container>
    );
}

export default Card;
