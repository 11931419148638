import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Financing from "../components/Solutions/Financing/Financing";

import Favicon from "../images/Favicon.png";

const FinancingPage = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Financing - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Finance Drivosity's safety and productivity solution including GPS-equipped car toppers. PEAC Solutions offers competitive rates, quick approvals, and flexible terms. Apply now!"/>
            </Helmet>
            <Layout component={<Financing/>}/>
        </>
    )
}

export default FinancingPage
