import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvVerticalCollapse from "../../Shared/DrvVerticalCollapse/DrvVerticalCollapse";
import Card from "./Card/Card";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {Text} from "./Financing.styles";
import {FinancingData, FinancingCollapseData} from "../Financing/Financing.data";

import Cover from "../../../images/Solutions/Financing/Cover.jpg";

const Financing = () => {
    return(
        <>
            {/*CONTENT*/}
            <DrvCover
                title={FinancingData[0].title}
                description={FinancingData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="container mx-auto py-14 flex flex-col lg:flex-row justify-between space-x-0 space-y-7 lg:space-x-7 lg:space-y-0">
                <Text>
                    {FinancingData[1].content}
                </Text>

                <Text>
                    {FinancingData[2].content}
                </Text>

                <Text>
                    {FinancingData[3].content}
                </Text>
            </div>

            {/*CONTENT*/}
            <div className="container mx-auto py-14 flex justify-center">
                <DrvButton to="https://apply.marlincapitalsolutions.com/auth/EF?partnerid=40798619BVBEAAW"
                           className="w-fit"
                           background="transparent"
                           color="#545454"
                           hoverbackground="var(--primary-color)"
                           hoverborder="var(--primary-color)"
                           hovercolor="#fff"
                >
                    APPLY TODAY
                </DrvButton>
            </div>

            {/*CONTENT*/}
            <div className="py-14">
                <DrvVerticalCollapse title='FAQ SECTION' items={FinancingCollapseData} />
            </div>

            {/*CONTENT*/}
            <div className="container mx-auto py-14 w-full flex flex-col md:flex-row justify-between gap-x-0 gap-y-7 md:gap-x-7 md:gap-y-0">
                <Card title={FinancingData[4].title}
                      subtitle={FinancingData[4].data.subtitle}
                      content={FinancingData[4].content}
                />
                <Card title={FinancingData[5].title}
                      subtitle={FinancingData[5].data.subtitle}
                      content={FinancingData[5].content}
                />
            </div>
        </>
    )
}

export default Financing
